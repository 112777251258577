import type { ProductListProps } from "@/components/ProductListV2/ProductList";
import { ProductList } from "@/components/ProductListV2/ProductList";
import type { FetchDataClientSide } from "@/hooks/useProductList/types";
import type { NumberOfColumnsPerDeviceOnPlp } from "@/react-components/ProductList/hooks/useSelectedColumnsNumber/useSelectedColumnsNumber.types";
import type { AVAILABILITY } from "@/react-components/Search/SearchFetchProductsHelper.types";
import type { MultipleAvailabilitySelectorProps } from "@/react-components/Sort/AvailabilitySelector/MultipleAvailabilitySelector";
import type { PreferredStoreIds } from "@/react-utils/Cookie";
import type { ProductCardDataV2 } from "@/react-utils/ProductData/product-card-data-helper";
import { withErrorBoundary } from "@/react-utils/WithErrorBoundary/with-error-boundary";
import type {
  LongTailData,
  ProductListingSmallBannerData,
} from "@xxl/frontend-api";
import type { FacetData, SortOrderData } from "@xxl/product-search-api";
import { useSelectedColumnsNumber } from "react-app/src/components/ProductList/hooks/useSelectedColumnsNumber";
import { ProductListItems } from "./ProductListItems";

// type CommonProps = {
//   availability: AVAILABILITY[];
//   facets: FacetData[];
//   isFetchingProducts: boolean;
//   numberOfColumnsPerDeviceOnPlp: NumberOfColumnsPerDeviceOnPlp | null;
//   products: ProductCardDataV2[];
//   selectedFilters: { [key: string]: string[] };
//   sortOrderData: SortOrderData;
//   storeIds: PreferredStoreIds;
//   storesData: MultipleAvailabilitySelectorProps["storesData"];
//   totalHits: number;
// }

// type BrandListProps = CommonProps & {
//   variant: "brand";
//   fetchDataClientSide: FetchDataClientSide | null;
// };

export type SearchResultsProductListProps = {
  autoCorrect?: string;
  availability: AVAILABILITY[];
  categoryId: string | null;
  categoryPath?: string[];
  facets: FacetData[];
  fetchDataClientSide: FetchDataClientSide | null;
  isArticleNumbersSearch: boolean;
  isFetchingProducts: boolean;
  longTailFacets: LongTailData[] | null;
  longTailPattern: string | null;
  numberOfColumnsPerDeviceOnPlp: NumberOfColumnsPerDeviceOnPlp | null;
  onFetch?: FetchDataClientSide;
  productListingSmallBanner: ProductListingSmallBannerData[] | null;
  products: ProductCardDataV2[];
  selectedFilters: { [key: string]: string[] };
  sortOrderData: SortOrderData;
  storeIds: PreferredStoreIds;
  storesData: MultipleAvailabilitySelectorProps["storesData"];
  totalHits: number;
};

const SearchResultsProductList = ({
  autoCorrect,
  availability,
  categoryId,
  categoryPath,
  facets,
  fetchDataClientSide,
  isFetchingProducts,
  longTailFacets,
  longTailPattern,
  numberOfColumnsPerDeviceOnPlp,
  onFetch,
  productListingSmallBanner,
  products,
  selectedFilters,
  sortOrderData,
  storeIds,
  storesData,
  totalHits,
  isArticleNumbersSearch,
}: SearchResultsProductListProps) => {
  const { gridNumber: selectedColumnsNumber } = useSelectedColumnsNumber(
    numberOfColumnsPerDeviceOnPlp
  );

  const props: ProductListProps = {
    autoCorrect,
    availability,
    categoryId,
    categoryPath,
    facets,
    isFetchingProducts,
    longTailFacets,
    longTailPattern,
    onFetch,
    fetchDataClientSide,
    selectedColumnsNumber,
    selectedFilters,
    sortOrderData,
    storesData,
    storeIds,
    totalHits,
    isArticleNumbersSearch,
  };

  return (
    <ProductList {...props}>
      <ProductListItems
        productListingSmallBanner={productListingSmallBanner}
        products={products}
        selectedColumnsNumber={selectedColumnsNumber}
      />
    </ProductList>
  );
};

export default withErrorBoundary(SearchResultsProductList);
